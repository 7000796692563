import { MvPage } from '@/lib/strapi-types/MvPage';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseMany } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

interface UseFindPagesProps {
  filters?: Record<string, unknown>;
  populate?: string[];
}

export async function useFindPages({ filters = {}, populate }: UseFindPagesProps = {}) {
  const defaultPopulate = [''];
  const allPopulate = populate ? [...defaultPopulate, ...populate] : defaultPopulate;

  const parsedQuery = stringify({
    populate: allPopulate,
    filters,
    pagination: {
      start: 0,
      limit: 100,
      withCount: true
    }
  });

  const { data } = await useFetchWithHead<Strapi4ResponseMany<MvPage['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.PAGES)}?${parsedQuery}`
  );

  const pagesData = data.value;

  return {
    pages: pagesData?.data ?? []
  };
}

<template>
  <div id="page-practical-information">
    <SectionHero v-if="pages" :title="countrySectionContext?.[0]?.title" :images="heroImage" />
    <Breadcrumb :parts="breadcrumbParts" />

    <SectionPracticalInfo
      v-if="
        pages?.[0]?.attributes.countryPageSection[0]?.tabs &&
        pages[0]?.attributes.countryPageSection[0]?.tabs.length > 0
      "
      :tab-info="countrySectionContext?.[0]?.tabs"
      :title="countrySectionContext?.[0]?.title"
      class="section-pratical-info"
    />
    <SectionYearlyWeather
      v-if="regions"
      :country="listContext[route.params.countryid]"
      :weathers-regions="regions"
    />
    <SectionGuide
      v-if="guides"
      class="section-guide"
      :guides="guides"
      :title="`Decouvrez tous les guides ${country?.attributes.label}`"
      :path-guide="`/${route.params.continentid}/${route.params.countryid}/`"
      root-guide
    />
    <LazySectionExperts
      class="experts"
      :style="{ background: '#F8F8F8' }"
      :country-context="countryContext"
    />
    <LazySectionJourneySuggestions :country-context="countryContext" title="Pour vous inspirer" />
    <SectionRatings />
  </div>
</template>
<script lang="ts" setup>
import Breadcrumb from '@/components/Breadcrumb.vue';
import { useFindPages } from '@/composables/pages/useFindPages';

import { CountryPageSection } from '@/lib/strapi-types/components/CountryPageSection';
const { context } = defineProps<{
  context: CountryPageSection['type'];
  title: string;
}>();
const route = useRoute();
let contextBreadcrumbParts: { name: string; url: string }[] = [];
const [{ pages }, { regions }, { continent }, { country }, { guides }] = await Promise.all([
  useFindPages({
    filters: {
      mv_country: { slug: route.params.countryid }
    },
    populate: [
      'countryPageSection.tabs.images',
      'countryPageSection.header',
      'countryPageSection.SEOMeta'
    ]
  }),
  useFindRegions({
    filters: { country: { slug: route.params.countryid } },
    populate: ['country', 'header', 'flightInfos']
  }),
  useFindOneContinent({
    filters: { slug: route.params.continentid, countries: { slug: route.params.countryid } }
  }),
  useFindOneCountry({
    filters: { slug: route.params.countryid }
  }),
  useFindGuides({
    filters: {
      country: { slug: route.params.countryid },
      mv_guide_level: { level: 5 }
    },
    populate: ['header', 'mv_guide_tag']
  })
]);

const headerStore = useHeaderStore();
const { listContext } = storeToRefs(headerStore);
const countryContext = route.params.countryid;

if (!country) {
  throw createError({
    statusCode: 404
  });
}

const countrySectionContext = pages?.[0]?.attributes.countryPageSection.filter(
  section => section.type === context
);

const heroImage = [
  {
    title: regions?.[0]?.attributes.header?.data?.attributes?.name ?? '',
    url:
      countrySectionContext?.[0]?.header?.data?.attributes?.url ??
      countrySectionContext?.[0]?.header_path
  }
];

if (context !== 'practicals-information') {
  contextBreadcrumbParts = [
    {
      name: countrySectionContext?.[0]?.title ?? '',
      url: `${route.path}/`
    }
  ];
}

const breadcrumbParts = [
  { name: continent?.attributes.name ?? '', url: `/${route.params.continentid}/` },
  {
    name: country?.attributes.label ?? '',
    url: `/${route.params.continentid}/${route.params.countryid}/`
  },
  {
    name: 'infos pratiques',
    url: `/${route.params.continentid}/${route.params.countryid}/infos-pratiques/`
  },
  ...contextBreadcrumbParts
];

useSeoMeta({
  title: `${countrySectionContext?.[0]?.SEOMeta?.Title ?? countrySectionContext?.[0]?.title ?? ''}`,
  ogTitle: `${countrySectionContext?.[0]?.SEOMeta?.Title ?? countrySectionContext?.[0]?.title ?? ''}`,
  description: `${countrySectionContext?.[0]?.SEOMeta?.Description ?? ''}`,
  ogDescription: `${countrySectionContext?.[0]?.SEOMeta?.Description ?? ''}`
});
useHead(() => ({
  link: [
    {
      rel: 'canonical',
      href: `${countrySectionContext?.[0]?.SEOMeta?.Canonical ?? ''}`
    }
  ],
  meta: [
    {
      name: 'robots',
      content: `${countrySectionContext?.[0]?.SEOMeta?.noindex ? 'noindex' : ''} ${countrySectionContext?.[0]?.SEOMeta?.nofollow ? 'nofollow' : ''}`
    }
  ]
}));
</script>
<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/z-index.scss';

#page-practical-information {
  flex-direction: column;

  .section-pratical-info {
    margin: 40px auto;

    @include breakpoints.mobile() {
      padding: 0 15px;
    }
  }

  .section-guide {
    margin: 60px auto;
  }
}
</style>
